import {
    createUnknownComponentVNode as _$cc,
    createTextVNode as _$ct,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {Breadcrumb, BreadcrumbItem, Icon} from 'kpc';

var _$tmp0 = {
    'className': 'ion-home',
    'size': 'small'
};
var _$tmp1 = {
    'className': 'ion-earth',
    'size': 'small'
};
var _$tmp2 = {
    'className': 'ion-planet',
    'size': 'small'
};
var _$tmp3 = {
    'className': 'ion-ios-arrow-thin-right separator'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$cc(Breadcrumb, {
        'children': [
            _$cc(BreadcrumbItem, {
                'to': '/',
                'children': [
                    _$cc(Icon, _$tmp0),
                    _$ct(' item 1\n    ')
                ]
            }),
            _$cc(BreadcrumbItem, {
                'to': '/components/breadcrumb/',
                'children': [
                    _$cc(Icon, _$tmp1),
                    _$ct(' item 2\n    ')
                ]
            }),
            _$cc(BreadcrumbItem, {
                'children': [
                    _$cc(Icon, _$tmp2),
                    _$ct(' item 3\n    ')
                ]
            })
        ],
        '$blocks': function($blocks) {
            var _$blocks = {}, __$blocks = _$ex({}, $blocks);
            return (
                (
                    (_$blocks['separator'] = function($super) {
                        return (
                            _$cc(Icon, _$tmp3)
                        );
                    }),
                    (__$blocks['separator'] = function($super, data) {
                        var block = $blocks['separator'];
                        var callBlock = function() {
                            return _$blocks['separator'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    })
                ),
                __$blocks
            );
        }.call($this, _$em)
    });
};